import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../api/axios";
import "./css/styles.css";
import Header from "./HeaderNotLoggedIn";

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const PWD_REGEX = /\S{6}/;
const CREW_REGEX = /\d{5}/;
const REGISTER_URL = "/register";

const Register = () => {
  const openTermsPopup = (e) => {
    e.preventDefault(); // Prevent the default navigation behavior
    const features =
      "width=390,height=480,scrollbars=yes,toolbar=no,location=no,menubar=no,status=no";
    window.open("/#/TermsAndConditions", "TermsPopup", features);
  };
  const emailRef = useRef();

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPassword, setMatchPassword] = useState("");
  const [matchIsValid, setMatchIsValid] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [crewCode, setCrewCode] = useState("");
  const [crewIsValid, setCrewIsValid] = useState(false);
  const [crewFocus, setCrewFocus] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [firstNameIsValid, setFirstNameIsValid] = useState(false);
  const [firstNameFocus, setFirstNameFocus] = useState(false);

  const [lastName, setLastName] = useState("");
  const [lastNameIsValid, setLastNameIsValid] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);

  const [rank, setRank] = useState("CPT");
  const [base, setBase] = useState("OSL");

  const [formIsValid, setFormIsValid] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setEmailIsValid(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setCrewIsValid(crewCode.length === 5);
  }, [crewCode]);

  useEffect(() => {
    setPasswordIsValid(PWD_REGEX.test(password));
  }, [password]);

  useEffect(() => {
    setPasswordIsValid(PWD_REGEX.test(password));
    setMatchIsValid(password === matchPassword);
  }, [password, matchPassword]);

  useEffect(() => {
    setErrMsg("");
  }, [email, crewCode, password, matchPassword, firstName, lastName]);

  useEffect(() => {
    let value = setTimeout(() => {
      setFormIsValid(
        emailIsValid &&
          passwordIsValid &&
          matchIsValid &&
          crewIsValid &&
          firstNameIsValid &&
          lastNameIsValid
      );
    }, 500);

    return () => {
      clearTimeout(value);
    };
  }, [emailIsValid, crewIsValid, passwordIsValid, matchIsValid]);

  const validateEmailHandler = () => {
    setEmailIsValid(EMAIL_REGEX.test(email));
  };

  const validateCrewHandler = () => {
    setCrewIsValid(CREW_REGEX.test(crewCode));
  };

  const validateFirstNameHandler = () => {
    setFirstNameIsValid(firstName.length >= 1);
  };

  const validateLastNameHandler = () => {
    setLastNameIsValid(lastName.length >= 1);
  };

  const [isChecked, setIsChecked] = useState(false);

  const validatePasswordHandler = () => {
    setPasswordIsValid(PWD_REGEX.test(password));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = EMAIL_REGEX.test(email);
    const v2 = PWD_REGEX.test(password);
    if (!v1 || !v2) {
      setErrMsg("Invalid Entry");
      return;
    }

    try {
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({
          email: email,
          password: password,
          crewCode: crewCode,
          firstName: firstName,
          lastName: lastName,
          rank: rank,
          base: base,
        })
      );
      setSuccess(true);
    } catch (err) {
      setErrMsg(
        err.response?.data?.message ||
          "Could not create user. Please try again later."
      );
    }
  };

  return (
    <>
      {success ? (
        <section className="main-div">
          <h1>Success!</h1>
          <p>
            <a href="/">Sign In</a>
          </p>
        </section>
      ) : (
        <>
        <Header />
        <section className="main-div">
          {/* <div className="form-actions">
            <div>
              <h1>Create Account</h1>
            </div>
            <div>
              <h5>
                <Link to="/">Login</Link>
              </h5>
            </div>
          </div> */}

          <p className={errMsg ? "errmsg" : "offscreen"}>{errMsg}</p>

          <form onSubmit={submitHandler} className="blue-box">
            <div className="control-group">
              <div className={"form-control"}>
                <label htmlFor="email" className="label-white">
                  Email
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={emailIsValid ? "valid" : "hide"}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={emailIsValid ? "hide" : "invalid"}
                  />
                </label>
                <input
                  type="email"
                  id="email"
                  ref={emailRef}
                  value={email}
                  autoComplete="off"
                  onChange={(e) => setEmail(e.target.value)}
                  onBlur={() => {
                    setEmailFocus(false);
                    validateEmailHandler();
                  }}
                  onFocus={() => setEmailFocus(true)}
                />
              </div>

              <div className={"form-control"}>
                <label htmlFor="crew" className="label-white">
                  Crew Number
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={crewIsValid ? "valid" : "hide"}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={crewIsValid ? "hide" : "invalid"}
                  />
                </label>
                <input
                  type="text"
                  id="crew"
                  value={crewCode}
                  autoComplete="off"
                  maxLength="5"
                  onChange={(e) => setCrewCode(e.target.value)}
                  onBlur={() => {
                    setCrewFocus(false);
                    validateCrewHandler();
                  }}
                  onFocus={() => setCrewFocus(true)}
                />
                <p
                  id="uidnote"
                  className={
                    crewFocus && !crewIsValid ? "instructions" : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} /> 5 digit number
                </p>
              </div>

              <div className={"form-control"}>
                <label htmlFor="first-name" className="label-white">
                  First Name
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={firstNameIsValid ? "valid" : "hide"}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={firstNameIsValid ? "hide" : "invalid"}
                  />
                </label>
                <input
                  type="text"
                  id="first-name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  onBlur={() => {
                    setFirstNameFocus(false);
                    validateFirstNameHandler();
                  }}
                  onFocus={() => setFirstNameFocus(true)}
                />
              </div>

              <div className={"form-control"}>
                <label htmlFor="last-name" className="label-white">
                  Last Name
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={lastNameIsValid ? "valid" : "hide"}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={lastNameIsValid ? "hide" : "invalid"}
                  />
                </label>
                <input
                  type="text"
                  id="last-name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  onBlur={() => {
                    setLastNameFocus(false);
                    validateLastNameHandler();
                  }}
                  onFocus={() => setLastNameFocus(true)}
                />
              </div>

              <div className="form-control">
                <label htmlFor="password" className="label-white">
                  Password
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={passwordIsValid ? "valid" : "hide"}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={passwordIsValid ? "hide" : "invalid"}
                  />
                </label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={() => setPwdFocus(true)}
                  onBlur={() => {
                    setPwdFocus(false);
                    validatePasswordHandler();
                  }}
                />
                <p
                  id="pwdnote"
                  className={
                    pwdFocus && !passwordIsValid ? "instructions" : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} /> 6 to 24 characters
                </p>
              </div>

              <div className="form-control">
                <label htmlFor="confirm-password" className="label-white">
                  Confirm Password
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={matchIsValid && matchPassword ? "valid" : "hide"}
                  />
                  <FontAwesomeIcon
                    icon={faTimes}
                    className={
                      matchIsValid || !matchPassword ? "hide" : "invalid"
                    }
                  />
                </label>
                <input
                  type="password"
                  id="confirm_password"
                  value={matchPassword}
                  required
                  onChange={(e) => setMatchPassword(e.target.value)}
                  onFocus={() => setMatchFocus(true)}
                  onBlur={() => {
                    setMatchFocus(false);
                    // validateMatchPwdHandler();
                  }}
                />
                <p
                  id="confirm-note"
                  className={
                    matchFocus && !matchIsValid ? "instructions" : "offscreen"
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                  Must match the first password input field.
                </p>
              </div>

              <div className="form-control">
                <table className="dropdown-table">
                  <tbody>
                    <tr>
                      <td>
                        <label htmlFor="rank" className="label-white">
                          Rank
                        </label>
                        <select
                          id="rank"
                          className="dropdown-control"
                          width="150"
                          value={rank}
                          onChange={(e) => setRank(e.target.value)}
                        >
                          <option value="CPT">CPT</option>
                          <option value="FO">FO</option>
                          <option value="SO">SO</option>
                          <option value="SCCM">SCCM</option>
                          <option value="CCM">CCM</option>
                        </select>
                      </td>
                      <td>
                        <label htmlFor="rank" className="label-white">
                          Base
                        </label>
                        <select
                          id="base"
                          className="dropdown-control"
                          value={base}
                          onChange={(e) => setBase(e.target.value)}
                        >
                          <option value="OSL">OSL</option>
                          <option value="CDG">CDG</option>
                          <option value="LGW">LGW</option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="form-actions">
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    id="termsCheckbox"
                    checked={isChecked}
                    className="large-checkbox"
                    onChange={(e) => setIsChecked(e.target.checked)}
                  />
                  <label htmlFor="termsCheckbox">
                    {/* <Link to="/register">Create new account</Link> */}
                    <a href="#" onClick={openTermsPopup} className="main-link">
                      Accept Terms and Conditions
                    </a>
                  </label>
                </div>
                <div>
                  <button disabled={!formIsValid}>Register</button>
                </div>
              </div>
            </div>
          </form>
        </section>
        </>
      )}
    </>
  );
};

export default Register;
