import React from 'react';
import './css/termsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div>
      <h1>Terms and Conditions</h1>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using the services provided by Norsiator.com, you agree to be bound by these terms and conditions. These terms may be updated from time to time, and it is your responsibility to review them periodically.
        </p>
      </section>

      <section>
        <h2>2. User Accounts</h2>
        <p>
          To access certain features of the website, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account information, including your username and password. You agree to notify us immediately of any unauthorized use of your account.
        </p>
      </section>

      <section>
        <h2>3. Privacy</h2>
        <p>
          Our Privacy Policy outlines how we collect, use, and share your personal information. By using our services, you agree to the terms of our Privacy Policy:<br />
          This Privacy Policy outlines how Norsiator.com collects, uses, and protects the personal information of users in compliance with the General Data Protection Regulation (GDPR). By using our website, you agree to the terms of this Privacy Policy.
        </p>
      </section>

      <section>
        <h2>3.1 Information We Collect</h2>
        <p>
          We may collect the following types of personal information:<br />
          Contact Information: Name, email address.<br />
          Work related information: Rank, base and crew number.<br />
          User Account Information: Username and password.<br />
          Work Schedule Information: Details of co-worker schedules shared on the platform.
        </p>
      </section>

      <section>
        <h2>3.2 How We Use Your Information</h2>
        <p>
          We use the collected information for the following purposes:<br />
          To provide and maintain our co-worker roster sharing services.<br />
          To communicate with users about their accounts and platform-related updates.<br />
          To improve our website and services.<br />
          To comply with legal obligations.
        </p>
      </section>

      <section>
        <h2>3.3. Legal Basis for Processing</h2>
        <p>
          We process personal data based on the following legal grounds:<br /><br />
          The user has given consent to the processing of their data for one or more specific purposes.<br />
          Processing is necessary for the performance of a contract to which the user is a party or to take steps at the user's request prior to entering into a contract.<br />
          Processing is necessary for compliance with a legal obligation to which we are subject.
        </p>
      </section>

      <section>
        <h2>3.4 Sharing of Information</h2>
        <p>
          We may share personal information with third parties under the following circumstances:<br /><br />
          With your explicit consent.<br />
          To comply with legal obligations.<br />
          To protect and defend our rights and property.
        </p>
      </section>

      <section>
        <h2>3.5 Data Retention</h2>
        <p>
          We retain personal information for as long as necessary to fulfill the purposes for which it was collected, including legal, accounting, or reporting requirements.
        </p>
      </section>

      <section>
        <h2>3.6 User Rights</h2>
        <p>
          Users have the following rights under GDPR:<br />
          The right to access personal data.<br />
          The right to rectification of inaccurate personal data.<br />
          The right to erasure of personal data.<br />
          The right to restrict processing of personal data.<br />
          The right to data portability.<br />
          The right to object to processing of personal data.<br />
          The right not to be subject to automated decision-making.<br />
          To exercise these rights or for any inquiries related to privacy, users can contact us at [Your Contact Information].
        </p>
      </section>

      <section>
        <h2>3.7 Security Measures</h2>
        <p>
          We implement appropriate technical and organizational measures to ensure a level of security appropriate to the risk. However, no data transmission over the internet can be guaranteed to be 100% secure.
        </p>
      </section>

      <section>
        <h2>3.8 Changes to the Privacy Policy</h2>
        <p>
          We may update this Privacy Policy to reflect changes to our information practices. Users are encouraged to review this page periodically for any changes.
        </p>
      </section>

      <section>
        <h2>4. User Responsibilities</h2>
        <p>
          You agree to use the website in compliance with all applicable laws and regulations. You are solely responsible for the content you post on the platform, and it must not violate any third-party rights or contain any unlawful or objectionable material.
        </p>
      </section>

      <section>
        <h2>5. Schedule Sharing</h2>
        <p>
          The primary purpose of our platform is to facilitate the sharing of co-worker schedules. By uploading and sharing schedules, you grant Norsiator.com the right to display and distribute the content for the intended purpose of the platform.
        </p>
      </section>

      <section>
        <h2>6. Prohibited Activities</h2>
        <p>
          You agree not to engage in any of the following activities:<br />
          Attempting to access, interfere with, or damage other users' accounts.<br />
          Uploading malicious software or engaging in any activity that could compromise the security of the website.<br />
          Using the website for any unlawful purpose.
        </p>
      </section>

      <section>
        <h2>7. Intellectual Property</h2>
        <p>
          All content and materials on the website, including but not limited to text, graphics, logos, and images, are the property of Norsiator.com and are protected by intellectual property laws. You may not use, reproduce, or distribute any content without our written permission.
        </p>
      </section>

      <section>
        <h2>8. Termination of Services</h2>
        <p>
          Norsiator.com reserves the right to suspend or terminate your access to the website at any time and for any reason, without prior notice.
        </p>
      </section>

      <section>
        <h2>9. Disclaimer of Warranties</h2>
        <p>
          The website is provided "as is" without any warranties, express or implied. Norsiator.com does not warrant that the services will be uninterrupted or error-free.
        </p>
      </section>

      <section>
        <h2>10. Limitation of Liability</h2>
        <p>
          To the fullest extent permitted by law, Norsiator.com shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
        </p>
      </section>

      <section>
        <h2>11. Governing Law</h2>
        <p>
          These terms and conditions are governed by and construed in accordance with the laws of Sweden. Any disputes arising from or in connection with these terms shall be subject to the exclusive jurisdiction of the courts in Sweden.
        </p>
      </section>

      <footer>
        By using Norsiator.com, you agree to these terms and conditions. If you have any questions or concerns, please contact us at <a href="mailto:support@norsiator.com">support@norsiator.com</a>.
      </footer>
    </div>
  );
};

export default TermsAndConditions;