import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../api/axios";
import "./css/styles.css";
import Header from "./HeaderNotLoggedIn";

const FORGOTPASSWORD_URL = "/forgotPassward";
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const ForgotPassword = () => {
  const emailRef = useRef();
  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);

  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    setErrMsg("");
    setSuccessMsg("");
  }, [email]);

  useEffect(() => {
    setEmailIsValid(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    let value = setTimeout(() => {
      setFormIsValid(emailIsValid);
    }, 500);

    return () => {
      clearTimeout(value);
    };
  }, [emailIsValid]);

  const validateEmailHandler = () => {
    setEmailIsValid(EMAIL_REGEX.test(email));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        FORGOTPASSWORD_URL,
        JSON.stringify({
          email,
        })
      );

      setSuccessMsg(
        "Please check your email for further instructions. Make sure you check spam/junk folder."
      );
    } catch (err) {
      setErrMsg(
        err.response?.data?.message || "Server error. Please try again later."
      );
    }
  };

  return (
    <>
    <Header />
    <section className="main-div">  
      <div className="blue-box">
        <div className="control-group">
          <div
            className={errMsg ? "alert alert-danger" : "offscreen"}
            role="alert"
          >
            {errMsg}
          </div>
          <div
            className={successMsg ? "alert alert-success" : "offscreen"}
            role="alert"
          >
            {successMsg}
          </div>
  
          <form onSubmit={submitHandler}>
            <div className="form-control">
              <label htmlFor="email">
                Email
                <FontAwesomeIcon
                  icon={faCheck}
                  className={emailIsValid ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={emailIsValid ? "hide" : "invalid"}
                />
              </label>
              <input
                type="email"
                id="email"
                ref={emailRef}
                autoComplete="off"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => validateEmailHandler()}
              />
            </div>
  
            <div className="form-actions">
              <div className="checkbox-container"></div>
              <div>
                <button disabled={!formIsValid}>Send</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    </>
  );
}

export default ForgotPassword;
