import React, { useContext } from "react";
import { Routes, Route } from 'react-router-dom';

import RequireAuth from './components/RequireAuth';
import PersistLogin from './components/PersistLogin';

import Layout from './components/Layout';
import Register from './components/Register';
import Login from './components/Login';
import ForgotPassword from './components/ForgotPassword';
import Rosters from './components/Rosters';
import Admin from './components/Admin';
import LinkPage from './components/LinkPage';
import Upload from './components/Upload';
import TermsAndConditions from './components/TermsAndConditions';
import EditProfile from './components/EditProfile';
import ResetPassword from './components/ResetPassword';
import EditRoster from './components/EditRoster';

import Missing from './components/Missing';
import Unauthorized from './components/Unauthorized';

// import AuthContext from './context/AuthProvider';
import useAuth from "./hooks/useAuth";

function App() {
  // const { auth } = useContext(AuthContext);
  const { auth } = useAuth();

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="forgotPassword" element={<ForgotPassword />} />
        <Route path="linkpage" element={<LinkPage />} />
        <Route path="termsAndConditions" element={<TermsAndConditions />} />
        <Route path="unauthorized" element={<Unauthorized />} />
        <Route path="resetPassword/:id" element={<ResetPassword />} />
        <Route path="editRoster/:id" element={<EditRoster />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={["admin", "user"]} />}>
            <Route
              path="rosters"
              element={
                <Rosters
                  render={(params) => ({ ...params })}
                  userRole={auth?.role}
                  userRank={auth?.rank}
                  userBase={auth?.base}
                />
              }
            />
            <Route
              path="upload"
              element={
                <Upload
                  render={(params) => ({ ...params })}
                  userId={auth?.id}
                />
              }
            />
            <Route
              path="editProfile"
              element={
                <EditProfile
                  render={(params) => ({ ...params })}
                  userId={auth?.id}
                />
              }
            />
          </Route>

          <Route element={<RequireAuth allowedRoles={["admin"]} />}>
            <Route path="admin" element={<Admin />} />            
          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}

export default App;