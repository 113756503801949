import React, { useState, useEffect } from "react";
import Header from "./HeaderLoggedIn";
import RosterData from "./RosterData";

import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

import "./css/styles.css";

import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const Rosters = ({ userRole, userRank, userBase }) => {
  const [open, setOpen] = useState(false);
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const [selectedDates, setSelectedDates] = React.useState();

  const isAdmin = userRole === "admin";
  const isPilot = ["CPT", "FO", "SO"].includes(userRank);
  const isCrew = ["SCCM", "CCM"].includes(userRank);

  let useRanks = [];
  let useBases = ["OSL", "CDG", "LGW"];
  let useSelectedRanks = [];
  let useSelectedBases = [];

  if (isAdmin) {
    useSelectedBases = useBases;
  } else if (userBase === "OSL" || userBase === "CDG") {
    useSelectedBases = ["OSL", "CDG"];
  } else if (userBase === "LGW") {
    useSelectedBases = ["LGW"];
  }

  // if (isAdmin) {
  //   useBases = ["OSL", "CDG", "LGW"];
  // } else if (userBase === "OSL" || userBase === "CDG") {
  //   useBases = ["OSL", "CDG"];
  // } else if (userBase === "LGW") {
  //   useBases = ["LGW"];
  // }

  if (isAdmin) {
    useRanks = ["CPT", "FO", "SO", "SCCM", "CCM"];
  } else if (isPilot) {
    useRanks = ["CPT", "FO", "SO"];
  } else if (isCrew) {
    useRanks = ["SCCM", "CCM"];
  }

  const [ranks, setRanks] = useState(useRanks);
  const [selectedRanks, setSelectedRanks] = useState(useRanks);

  const [bases, setBases] = useState(useBases);
  const [selectedBases, setSelectedBases] = useState(useSelectedBases);

  const [startDate, setStartDate] = useState(new Date());

  const endD = new Date();
  endD.setDate(endD.getDate() + 100);
  const [endDate, setEndDate] = useState(endD);

  const [rosterDataKey, setRosterDataKey] = useState(0);

  const submitHandler = () => {
    onCloseModal();
    if (selectedDates) {
      if (!selectedDates.to) {
        setEndDate(selectedDates.from);
      } else {
        setEndDate(selectedDates.to);
      }
      setStartDate(selectedDates.from);
    }

    setRosterDataKey((prevKey) => prevKey + 1);
  };

  return (
    <section className="main-div-large">
      <Header title="Rosters" />

      <div className="blue-box">
        <div>
          <div style={{ marginBottom: '10px' }}>
            <button onClick={onOpenModal}>Filter</button>
          </div>
          <Modal open={open} onClose={onCloseModal} center>
            <div className="div-same-line-dropdown">
              <div className="dropdown-container">
                <DropdownMultiselect
                  options={ranks}
                  selected={selectedRanks}
                  name="countries"
                  placeholder="Select Rank"
                  buttonClass="btn-primary"
                  handleOnChange={(selected) => {
                    setSelectedRanks(selected);
                  }}
                />
              </div>

              <div className="dropdown-container">
                <DropdownMultiselect
                  options={bases}
                  selected={selectedBases}
                  name="countries"
                  placeholder="Select Rank"
                  buttonClass="btn-primary"
                  handleOnChange={(selected) => {
                    setSelectedBases(selected);
                  }}
                />
              </div>
            </div>

            <DayPicker
              mode="range"
              selected={selectedDates}
              onSelect={setSelectedDates}
            />

            <button type="submit" onClick={submitHandler}>
              Filter
            </button>
          </Modal>
        </div>

        <div>
          <RosterData
            key={rosterDataKey}
            rank={selectedRanks}
            base={selectedBases}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
    </section>
  );
};

export default Rosters;
