import { useRef, useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Link, useParams } from "react-router-dom";
import Header from "./HeaderLoggedIn";

const ROSTER_URL = "/getSchedule/";
const PATCH_URL = "/schedule/";

const Rosters = () => {
  const axiosPrivate = useAxiosPrivate();
  const { id } = useParams();

  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [rosterData, setRosterData] = useState(null);
  const [formData, setFormData] = useState("");
  const [display, setDisplay] = useState("true");

  useEffect(() => {
    if (rosterData) {
      let temp = "";
      for (const line of rosterData.rawData) {
        temp += line + "\n";
      }
      setFormData(temp);
      setDisplay(rosterData.display.toString());
    }
  }, [rosterData]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getRoster = async () => {
      try {
        const res = await axiosPrivate.get(ROSTER_URL + id, {
          signal: controller.signal,
        });
        setRosterData(res.data);
      } catch (err) {
        console.log(err);
        setErrMsg(err.response?.data?.message || "Could not get user data.");
      }

      return () => {
        isMounted = false;
        controller.abort();
      };
    };
    getRoster();
  }, [id]);

  const submitHandler = async (e) => {
    e.preventDefault();
    await patchRoster();
  };

  const patchRoster = async () => {
    try {
      const body = {
        rawData: formData.split("\n"),
        display: display,
      };
      const res = await axiosPrivate.patch(PATCH_URL + id, body);
      setSuccessMsg("Roster successfully updated!");
    } catch (err) {
      setErrMsg(err.response?.data?.message || "Could not update roster");
    }
  };

  return (
    <section className="main-div-large">
      <Header title="Admin" />

      <div className="gray-box">
        <div
          className={errMsg ? "alert alert-danger" : "offscreen"}
          role="alert"
        >
          {errMsg}
        </div>
        <div
          className={successMsg ? "alert alert-success" : "offscreen"}
          role="alert"
        >
          {successMsg}
        </div>

        <form onSubmit={submitHandler}>
          <div className="checkbox-container">
            <table>
              <tbody>
                <tr>
                  <td>
                    <strong>Display:</strong>
                  </td>
                  <td>
                    <label>
                      <input
                        type="radio"
                        name="display"
                        value="true"
                        checked={display === "true"}
                        onChange={(e) => setDisplay(e.target.value)}
                      />{" "}
                      True
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="display"
                        value="false"
                        checked={display === "false"}
                        onChange={(e) => setDisplay(e.target.value)}
                      />{" "}
                      False
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="control-group">
            <textarea
              className="textarea-control"
              id="rawData"
              cols="45"
              rows="20"
              value={formData}
              autoComplete="off"
              onChange={(e) => setFormData(e.target.value)}
            ></textarea>

            <div className="form-actions">
              <div className="checkbox-container"></div>
              <div>
                <button>Update</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Rosters;
