import React, { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

import "handsontable/dist/handsontable.full.min.css";
import Handsontable from "handsontable/base";
import { registerAllModules } from "handsontable/registry";
import { registerRenderer, textRenderer } from "handsontable/renderers";
import { HotTable } from "@handsontable/react";
registerAllModules();

const ROSTERS_URL = "/schedules";

const RosterData = ({ rank, base, startDate, endDate }) => {
  const axiosPrivate = useAxiosPrivate();

  const [errMsg, setErrMsg] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getRosters = async () => {   
      try {
        const res = await axiosPrivate.post(
          ROSTERS_URL,
          JSON.stringify({
            rank: rank,
            base: base,
            startDate: startDate,
            endDate: endDate,
          }),
          {
            signal: controller.signal,
          }
        );
        if (isMounted) {
          if (res.data.length === 0) {
            setErrMsg("No rosters found.");
          } else {
            setData(res.data);
          }
        }
      } catch (err) {
        setErrMsg(err.response?.data?.message || "Could not get rosters.");
      }

    
      return () => {
        isMounted = false;
        controller.abort();
      };
    };    
    getRosters();
  }, []);

  function valueRenderer(instance, td, row, col, prop, value, cellProperties) {
    textRenderer.apply(this, arguments);

    const flightRegEx = /[A-Z]{3}-[A-Z]{3}/;

    if (value === "OFF" || value === "REST") {
      td.style.background = "white";
    }

    if (value === "RSV") {
      td.style.background = "#E6E6E6";
    }

    if (value === "STBY" || value === "SBYPOS") {
      td.style.background = "#CCCCCC";
    }

    if (value === "???") {
      td.style.background = "#eeee90";
    }

    if (
      value === "-" ||
      value === "FLTPOS" ||
      value === "DH" ||
      value === "GD" ||
      value === "HOTEL" ||
      value === "SIM" ||
      value === "CBT" ||
      flightRegEx.test(value)
    ) {
      td.style.background = "#bfee90";
    }

    if (!value || value === "") {
      td.style.background = "#EEE";
    }
  }
  //  maps function to a lookup string
  registerRenderer("valueRenderer", valueRenderer);

  return (
    <>
      {errMsg ? (
        <div className="alert alert-danger" role="alert">
          {errMsg}
        </div>
      ) : (
        <HotTable
          data={data.data}
          colHeaders={data.colHeaders}
          rowHeaders={data.rowHeaders}
          readOnly={true}
          height="502"
          width="auto"
          autoColumnSize={true}
          stretchH="all"
          licenseKey="non-commercial-and-evaluation"
          settings={{}}
          afterSelection={function (row, col, row2, col2) {
            const meta = this.getCellMeta(row2, col2);

            if (meta.readOnly) {
              this.updateSettings({ fillHandle: false });
            } else {
              this.updateSettings({ fillHandle: true });
            }
          }}
          cells={function (row, col) {
            const cellProperties = {};
            const data = this.instance.getData();

            cellProperties.renderer = "valueRenderer";
            return cellProperties;
          }}
        />
      )}
    </>
  );
};

export default RosterData;
