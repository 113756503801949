import { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Header from "./HeaderLoggedIn";

const USER_URL = "/user/";
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const CREW_REGEX = /\d{5}/;

const Rosters = ({ userId }) => {
  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const emailRef = useRef();
  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);

  const [crewCode, setCrewCode] = useState("");
  const [crewIsValid, setCrewIsValid] = useState(false);
  const [crewFocus, setCrewFocus] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [firstNameIsValid, setFirstNameIsValid] = useState(false);
  const [firstNameFocus, setFirstNameFocus] = useState(false);

  const [lastName, setLastName] = useState("");
  const [lastNameIsValid, setLastNameIsValid] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);

  const [rank, setRank] = useState("");
  const [base, setBase] = useState("");

  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    setErrMsg("");
  }, [email, crewCode, firstName, lastName]);

  useEffect(() => {
    let value = setTimeout(() => {
      setFormIsValid(
        emailIsValid && crewIsValid && firstNameIsValid && lastNameIsValid
      );
    }, 500);

    return () => {
      clearTimeout(value);
    };
  }, [emailIsValid, crewIsValid, firstNameIsValid, lastNameIsValid]);

  const validateEmailHandler = () => {
    setEmailIsValid(EMAIL_REGEX.test(email));
  };

  const validateCrewHandler = () => {
    setCrewIsValid(CREW_REGEX.test(crewCode));
  };

  const validateFirstNameHandler = () => {
    setFirstNameIsValid(firstName.length >= 1);
  };

  const validateLastNameHandler = () => {
    setLastNameIsValid(lastName.length >= 1);
  };

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const setUserData = async () => {
      try {
        const res = await axiosPrivate.get(USER_URL + userId, {
          signal: controller.signal,
        });
        const data = res.data;
        setEmail(data.email);
        setCrewCode(data.crewCode);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setRank(data.rank);
        setBase(data.base);

        setEmailIsValid(true);
        setCrewIsValid(true);
        setFirstNameIsValid(true);
        setLastNameIsValid(true);
        // setFormIsValid(true);
      } catch (err) {
        console.log(err);
        setErrMsg(err.response?.data?.message || "Could not get user data.");
      }

      return () => {
        isMounted = false;
        controller.abort();
      };
    };
    setUserData();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    await patchUser();
  };

  const patchUser = async () => {
    try {
      const body = {
        email: email,
        crewCode: crewCode,
        firstName: firstName,
        lastName: lastName,
        rank: rank,
        base: base,
      };

      const res = await axiosPrivate.patch(USER_URL + userId, body);
      setSuccessMsg("User successfully updated!");
    } catch (err) {
      setErrMsg(err.response?.data?.message || "Could not update user.");
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete your user?"
    );

    if (confirmDelete) {
      await deleteUser();
    }
  };

  const deleteUser = async () => {
    try {
      const res = await axiosPrivate.delete(USER_URL + userId);
      navigate("/", { replace: true });
    } catch (err) {
      console.log(err);
      setErrMsg(err.response?.data?.message || "Could not delete user.");
    }
  };

  return (
    <section className="main-div">
      <Header title="Profile" />

      <div className="blue-box">
        <div
          className={errMsg ? "alert alert-danger" : "offscreen"}
          role="alert"
        >
          {errMsg}
        </div>
        <div
          className={successMsg ? "alert alert-success" : "offscreen"}
          role="alert"
        >
          {successMsg}
        </div>

        <div className="form-actions">
          <div></div>
          <div>
            <button onClick={handleDelete} class="btn btn-danger">
              Delete User
            </button>
          </div>
        </div>

        <form onSubmit={submitHandler}>
          <div className="control-group">
            <div className={"form-control"}>
              <label htmlFor="email" className="label-white">
                Email
                <FontAwesomeIcon
                  icon={faCheck}
                  className={emailIsValid ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={emailIsValid ? "hide" : "invalid"}
                />
              </label>
              <input
                type="email"
                id="email"
                ref={emailRef}
                value={email}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => {
                  setEmailFocus(false);
                  validateEmailHandler();
                }}
                onFocus={() => setEmailFocus(true)}
              />
            </div>

            <div className={"form-control"}>
              <label htmlFor="crew" className="label-white">
                Crew Number
                <FontAwesomeIcon
                  icon={faCheck}
                  className={crewIsValid ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={crewIsValid ? "hide" : "invalid"}
                />
              </label>
              <input
                type="text"
                id="crew"
                value={crewCode}
                autoComplete="off"
                maxLength="5"
                onChange={(e) => setCrewCode(e.target.value)}
                onBlur={() => {
                  setCrewFocus(false);
                  validateCrewHandler();
                }}
                onFocus={() => setCrewFocus(true)}
              />
              <p
                id="uidnote"
                className={
                  crewFocus && !crewIsValid ? "instructions" : "offscreen"
                }
              >
                <FontAwesomeIcon icon={faInfoCircle} /> 5 digit number
              </p>
            </div>

            <div className={"form-control"}>
              <label htmlFor="first-name" className="label-white">
                First Name
                <FontAwesomeIcon
                  icon={faCheck}
                  className={firstNameIsValid ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={firstNameIsValid ? "hide" : "invalid"}
                />
              </label>
              <input
                type="text"
                id="first-name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                onBlur={() => {
                  setFirstNameFocus(false);
                  validateFirstNameHandler();
                }}
                onFocus={() => setFirstNameFocus(true)}
              />
            </div>

            <div className={"form-control"}>
              <label htmlFor="last-name" className="label-white">
                Last Name
                <FontAwesomeIcon
                  icon={faCheck}
                  className={lastNameIsValid ? "valid" : "hide"}
                />
                <FontAwesomeIcon
                  icon={faTimes}
                  className={lastNameIsValid ? "hide" : "invalid"}
                />
              </label>
              <input
                type="text"
                id="last-name"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                onBlur={() => {
                  setLastNameFocus(false);
                  validateLastNameHandler();
                }}
                onFocus={() => setLastNameFocus(true)}
              />
            </div>

            <div className="form-control">
              <table className="dropdown-table">
                <tbody>
                  <tr>
                    <td>
                      <label htmlFor="rank" className="label-white">
                        Rank
                      </label>
                      <select
                        id="rank"
                        className="dropdown-control"
                        width="150"
                        value={rank}
                        onChange={(e) => setRank(e.target.value)}
                      >
                        <option value="CPT">CPT</option>
                        <option value="FO">FO</option>
                        <option value="SO">SO</option>
                        <option value="SCCM">SCCM</option>
                        <option value="CCM">CCM</option>
                      </select>
                    </td>
                    <td>
                      <label htmlFor="rank" className="label-white">
                        Base
                      </label>
                      <select
                        id="base"
                        className="dropdown-control"
                        value={base}
                        onChange={(e) => setBase(e.target.value)}
                      >
                        <option value="OSL">OSL</option>
                        <option value="CDG">CDG</option>
                        <option value="LGW">LGW</option>
                      </select>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="form-actions">
              <div className="checkbox-container"></div>
              <div>
                <button disabled={!formIsValid}>Update User</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default Rosters;
