import { useRef, useState, useEffect } from "react";
import {
  faCheck,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useParams } from "react-router-dom";
import axios from "../api/axios";
import Header from "./HeaderNotLoggedIn";
import "./css/styles.css";

const RESETPASSWORD_URL = "/resetPassword/";
const PWD_REGEX = /\S{6}/;

const ResetPassword = () => {
  const { id } = useParams();
  console.log(id);

  const passwordRef = useRef();
  useEffect(() => {
    passwordRef.current.focus();
  }, []);

  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [password, setPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [pwdFocus, setPwdFocus] = useState(false);

  const [matchPassword, setMatchPassword] = useState("");
  const [matchIsValid, setMatchIsValid] = useState(false);

  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    setErrMsg("");
    setSuccessMsg("");
  }, [password, matchPassword]);

  useEffect(() => {
    setPasswordIsValid(PWD_REGEX.test(password));
  }, [password]);

  useEffect(() => {
    setPasswordIsValid(PWD_REGEX.test(password));
    setMatchIsValid(password === matchPassword);
  }, [password, matchPassword]);

  useEffect(() => {
    let value = setTimeout(() => {
      setFormIsValid(passwordIsValid && matchIsValid);
    }, 500);

    return () => {
      clearTimeout(value);
    };
  }, [passwordIsValid, matchIsValid]);

  const submitHandler = async (e) => {
    e.preventDefault();
    await patchUser();
  };

  const patchUser = async () => {
    try {
      const res = await axios.patch(
        RESETPASSWORD_URL + id,
        JSON.stringify({
          password,
        })
      );
      setSuccessMsg("Password updated!");
    } catch (err) {
      setErrMsg(
        err.response?.data?.message || "Server error. Please try again later"
      );
    }
  };

  return (
    <>
      {successMsg ? (
        <section className="main-div">
          <h1>Password updated!</h1>
          <p>
            <a href="/">Sign In</a>
          </p>
        </section>
      ) : (
        <>
          <Header />
          <section className="main-div">
            <form onSubmit={submitHandler} className="blue-box">
              <div className="control-group">
                <div
                  className={errMsg ? "alert alert-danger" : "offscreen"}
                  role="alert"
                >
                  {errMsg}
                </div>
                <div
                  className={successMsg ? "alert alert-success" : "offscreen"}
                  role="alert"
                >
                  {successMsg}
                </div>

                <div className={"form-control"}>
                  <label htmlFor="password" className="label-white">
                    Enter a new password:
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={passwordIsValid ? "valid" : "hide"}
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={passwordIsValid ? "hide" : "invalid"}
                    />
                  </label>
                  <input
                    type="password"
                    id="password"
                    ref={passwordRef}
                    autoComplete="off"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={() => {
                      setPwdFocus(false);
                    }}
                    onFocus={() => {
                      setPwdFocus(true);
                    }}
                  />
                  <p
                    id="pwdnote"
                    className={
                      pwdFocus && !passwordIsValid
                        ? "instructions"
                        : "offscreen"
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} /> 6 to 24 characters
                  </p>
                </div>

                <div className={"form-control"}>
                  <label htmlFor="match-password" className="label-white">
                    Confirm password:
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={
                        matchIsValid && matchPassword ? "valid" : "hide"
                      }
                    />
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={
                        matchIsValid && matchPassword ? "hide" : "invalid"
                      }
                    />
                  </label>
                  <input
                    type="password"
                    id="match-password"
                    autoComplete="off"
                    value={matchPassword}
                    onChange={(e) => setMatchPassword(e.target.value)}
                  />
                </div>

                <div className="form-actions">
                  <div className="checkbox-container"></div>
                  <div>
                    <button disabled={!formIsValid}>Update</button>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </>
      )}
    </>
  );
};

export default ResetPassword;
