import { useRef, useState, useEffect } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../api/axios";
import Header from "./HeaderNotLoggedIn";
import "./css/styles.css";

const LOGIN_URL = "/login";
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const Login = () => {
  const { setAuth, persist, setPersist } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const openTermsPopup = (e) => {
    e.preventDefault(); // Prevent the default navigation behavior
    const features =
      "width=390,height=480,scrollbars=yes,toolbar=no,location=no,menubar=no,status=no";
    window.open("/#/TermsAndConditions", "TermsPopup", features);
  };

  const emailRef = useRef();
  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const [errMsg, setErrMsg] = useState("");

  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordIsValid, setPasswordIsValid] = useState(false);

  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  useEffect(() => {
    setEmailIsValid(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setPasswordIsValid(password.length >= 1);
  }, [password]);

  useEffect(() => {
    let value = setTimeout(() => {
      setFormIsValid(emailIsValid && passwordIsValid);
    }, 500);

    return () => {
      clearTimeout(value);
    };
  }, [emailIsValid, passwordIsValid]);

  const validateEmailHandler = () => {
    setEmailIsValid(EMAIL_REGEX.test(email));
  };

  const validatePasswordHandler = () => {
    setPasswordIsValid(password.length >= 1);
  };

  useEffect(() => {
    setPersist(true);
    localStorage.setItem("persist", persist);
  }, [persist]);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        LOGIN_URL,
        JSON.stringify({
          email,
          password,
        })
      );
      
      const { id, schedule, role, rank, base, accessToken } = res.data;
      setAuth({ id, role, rank, base, accessToken });
      setEmail("");
      setPassword("");

      if (schedule) {
      } else {
        // navigate('/upload', { state: { message: 'Please upload your latest roster' } });
      }

      navigate("/rosters", { replace: true });
    } catch (err) {
      setErrMsg(err.response?.data?.message || "Could not login.");
    }
  };

  return (
    <>
      <Header />
      <section className="main-div">
        <form onSubmit={submitHandler} className="blue-box">
          <div className="control-group">
            <div
              className={errMsg ? "alert alert-danger" : "offscreen"}
              role="alert"
            >
              {errMsg}
            </div>

            <div className={"form-control"}>
              <label htmlFor="email" className="label-white">
                Email
              </label>
              <input
                type="email"
                id="email"
                ref={emailRef}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() => validateEmailHandler()}
              />
            </div>

            <div className="form-control">
              <label htmlFor="password" className="label-white">
                Password
              </label>
              <input
                type="password"
                id="password"
                value={password}
                autoComplete="off"
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() => validatePasswordHandler()}
              />
            </div>

            <div className="form-actions">
              <div>
                <Link to="/forgotPassword" className="main-link">
                  Forgot password?
                </Link>
                <br></br>
                <a href="#" onClick={openTermsPopup} className="main-link">
                  Terms and conditions
                </a>
              </div>
              <div>
                <button type="submit" disabled={!formIsValid}>
                  Login
                </button>
              </div>
            </div>
          </div>
        </form>

        <div className="form-actions">
          <div></div>
          <div className="bottom-align-container">
            <h5>
              <Link to="/register" className="login-a">
                Create new account
              </Link>
            </h5>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
