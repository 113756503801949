import React from "react";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import Header from "./HeaderLoggedIn";

const UPLOAD_URL = "/fileUpload";

const Upload = ({ userId }) => {
  const axiosPrivate = useAxiosPrivate();

  const [infoMsg, setInfoMsg] = useState(
    "Login to CrewConnex and download your roster in PDF-format. Save it to your device and proceed to upload it using the feature below."
  );
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [file, setFile] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);

  const changeFileHandler = (event) => {
    setFile(event.target.files[0]);
    setFormIsValid(true);
  };

  const onSubmit = async (event) => {
    let isMounted = true;
    const controller = new AbortController();

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("userId", userId);

      await axiosPrivate.post(UPLOAD_URL, formData, {
        headers: {
          "Content-Type": "undefined",
        },
      });
      setFile("");
      setInfoMsg("");
      setErrMsg("");
      setSuccessMsg("Roster successfully uploaded!");
    } catch (err) {
      setInfoMsg("");
      setSuccessMsg("");
      setErrMsg(err.response?.data?.message || "Could not upload roster.");
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  return (
    <section className="main-div">
      <Header title="Upload" />

      <div className="blue-box">
        <div
          className={infoMsg ? "alert alert-info" : "offscreen"}
          role="alert"
        >
          {infoMsg}
        </div>

        <div
          className={errMsg ? "alert alert-danger" : "offscreen"}
          role="alert"
        >
          {errMsg}
        </div>
        <div
          className={successMsg ? "alert alert-success" : "offscreen"}
          role="alert"
        >
          {successMsg}
        </div>

        <div className="control-group">
          <div className={"form-control"}>
            <label htmlFor="file" className="label-white">
              File
            </label>
            <input type="file" id="file" onChange={changeFileHandler} />
          </div>

          <div className="div-right">
            <button onClick={onSubmit} disabled={!formIsValid}>
              Upload
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Upload;
