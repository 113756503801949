import React, { useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import useAuth from "../hooks/useAuth";

const Header = ({ title }) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const logout = useLogout();

  const isAdmin = auth?.role === "admin";

  const signOut = async () => {
    await logout();
    navigate("/");
  };

  return (
    <div className="form-actions">
      <div>
      </div>
      <div>
        <h5>
          <Link to="/rosters">Rosters</Link> | <Link to="/upload">Upload</Link>{" "}
          | <Link to="/editProfile">Profile</Link> |{" "}
          {isAdmin ? (
            <>
              <Link to="/admin">Admin</Link> |{" "}
            </>
          ) : null}
          <Link to="/" onClick={signOut}>
            Log out
          </Link>
        </h5>
      </div>
    </div>
  );
};

export default Header;
