import React from "react";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "./HeaderLoggedIn";

const ROSTERS_URL = "/getScheduleList";
const DELETE_URL = "/schedule/";

function formatDate(date) {
  date = new Date(date);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

function formatEmail(inputString) {
  if (inputString.length <= 15) {
    return inputString;
  } else {
    return inputString.slice(0, 15);
  }
}

const Admin = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState("");
  const [rosters, setRosters] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getRosters = async () => {
      try {
        const res = await axiosPrivate.get(ROSTERS_URL, {
          signal: controller.signal,
        });
        if (isMounted) {
          if (res.data.length === 0) {
            setErrMsg("No rosters found.");
          } else {
            setRosters(res.data);
          }
        }
      } catch (err) {
        setErrMsg(err.response?.data?.message || "Could not get rosters");
      }

      return () => {
        isMounted = false;
        controller.abort();
      };
    };
    getRosters();
  }, []);

  const handleDelete = async (rosterId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this roster?"
    );

    if (confirmDelete) {
      await deleteUser(rosterId);
    }
  };

  const deleteUser = async (rosterId) => {
    try {
      const res = await axiosPrivate.delete(DELETE_URL + rosterId);
    } catch (err) {
      console.log(err);
      setErrMsg(err.response?.data?.message || "Could not delete roster");
    }
  };

  return (
    <section className="ain-div-large">
      <Header title="Admin" />

      <div className="gray-box">
        <div
          className={errMsg ? "alert alert-danger" : "offscreen"}
          role="alert"
        >
          {errMsg}
        </div>

        <table className="list-table">
          <tbody>
            <tr>
              <th>
                <strong>Email</strong>
              </th>
              <th>
                <strong>Crew</strong>
              </th>
              <th>
                <strong>Start Date</strong>
              </th>
              <th>
                <strong>End Date</strong>
              </th>
              <th></th>
            </tr>
            {rosters.map((roster) => (
              <tr key={roster._id}>
                <td>{formatEmail(roster.user.email)}</td>
                <td>{roster.user.crewCode}</td>
                <td>{formatDate(roster.startDate)}</td>
                <td>{formatDate(roster.startDate)}</td>
                <td>
                  <Link to={`/editRoster/${roster._id}`}>Edit</Link> {" / "}
                  <Link to="#" onClick={() => handleDelete(roster._id)}>
                    Delete
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="control-group">
          <div className={"form-control"}></div>
        </div>
      </div>
    </section>
  );
};

export default Admin;
